import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import './registerServiceWorker'

loadFonts();

createApp(App)
  .use(router)
  .use(vuetify)
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyCggdEc8ZtymLhTm12J9YWwgO8HPTkwScs",
    },
  })
  .mount("#app");
