export default interface FoodMenu {
  name: string;
  preText?: string;
  number: number;
  ingredients: string;
  price: number;
  priceArray?: PriceArray[];
  allergens: string;
  img: string;
  imgScale?: string;
  foodType: FoodType;
}

export interface PriceArray {
  desc: string;
  price: number;
}

export enum FoodType {
  AmericanPizza = 0,
  ItalianPizza = 1,
  Pasta = 2,
  BarneMeny = 3,
  SmåRetter = 4,
  FastFood = 5,
  FastFoodBurger = 6,
  FastFoodKebab = 7,
  MeatAndFish = 8,
  Tilbehør = 9,
}

const AmericanPizzas: FoodMenu[] = [
  {
    name: "Jorden",
    number: 701,
    ingredients: "Ost, Tomatsaus, Kebabkjøtt, Champignon og Løk",
    price: 239,
    allergens: "Hvitløk, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.AmericanPizza,
  },
  {
    name: "Sol (sterk)",
    number: 702,
    ingredients:
      "Ost, Tomatsaus, Pepperoni, Marinert Biffkjøtt, Mais, Løk (Sterk)",
    price: 229,
    allergens: "Hvitløk, Mais, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.AmericanPizza,
  },
  {
    name: "Månen",
    number: 703,
    ingredients: "Ost, Tomatsaus, Skinke, Champignon",
    price: 209,
    allergens: "Hvitløk, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.AmericanPizza,
  },
  {
    name: "Mars",
    number: 704,
    ingredients: "Ost, Tomatsaus, Kjøttboller, Skinke, Salami, Paprika",
    price: 229,
    allergens: "Hvitløk, Mais, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.AmericanPizza,
  },
  {
    name: "Jupiter",
    number: 705,
    ingredients:
      "Ost, Tomatsaus, Bacon, Marinert Biffkjøtt, Mais, Paprika, Løk, Tomat",
    price: 229,
    allergens: "Hvitløk, Mais, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.AmericanPizza,
  },
  {
    name: "Neptun",
    number: 706,
    ingredients:
      "Ost, Tomatsaus, Skinke, Marinert Biffkjøtt, Salami, Champignon",
    price: 229,
    allergens: "Hvitløk, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.AmericanPizza,
  },
  {
    name: "Saturn (Vegetar)",
    number: 707,
    ingredients:
      "Ost, Tomatsaus, Tomat, Løk, Oliven, Champignon, Paprika, Ananas, Purreløk",
    price: 229,
    allergens: "Hvitløk, Mais, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.AmericanPizza,
  },
  {
    name: "Pluto",
    number: 708,
    ingredients:
      "Ost, Tomatsaus, Skinke, Peperoni, Marinert Biffkjøtt, Paprika, Løk, Oliven",
    price: 239,
    allergens: "Hvitløk, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.AmericanPizza,
  },
  {
    name: "Venus",
    number: 709,
    ingredients: "Ost, Tomatsaus, Kylling-kebab, Paprika, Purreløk, Mais",
    price: 229,
    allergens: "Hvitløk, Mais, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.AmericanPizza,
  },
  {
    name: "Orion",
    number: 710,
    ingredients:
      "Ost, Tomatsaus, Muslinger, Reker, Champignon, Paprika, Purreløk",
    price: 229,
    allergens: "Bløtdyr, Skalldyr, Hvitløk, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.AmericanPizza,
  },
  {
    name: "Stjerneskudd",
    number: 711,
    ingredients: "Ost, Tomatsaus, Marinert Biffkjøtt, Paprika, Champignon",
    price: 229,
    allergens: "Hvitløk, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.AmericanPizza,
  },
  {
    name: "Åsgårdstrand spesial",
    number: 713,
    ingredients: "Ost, Tomatsaus, Skinke, Pepperoni, Kebabkjøtt",
    price: 229,
    allergens: "Hvitløk, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.AmericanPizza,
  },
  {
    name: "Lag din egen planet",
    number: 712,
    ingredients:
      "Sett sammen din egen pizza (Max. 5 ingredienser) Ekstra ingredienser: +20,- kr",
    price: 229,
    allergens: "",
    img: "pizza1.png",
    foodType: FoodType.AmericanPizza,
  },
];

const ItalianPizzas: FoodMenu[] = [
  {
    name: "La Luna",
    number: 301,
    ingredients: "Ost, Tomatsaus, Skinke, Biff, Løk.",
    price: 144,
    allergens: "Hvitløk, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.ItalianPizza,
  },
  {
    name: "Calzone (Innbakt)",
    number: 302,
    ingredients: "Ost, Tomatsaus, Skinke. (Innbakt)",
    price: 144,
    allergens: "Hvitløk, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.ItalianPizza,
  },
  {
    name: "Pizza Vera",
    number: 303,
    ingredients: "Ost, Tomatsaus, Skinke, Paprika, Champignon, Reker.",
    price: 144,
    allergens: "Hvitløk, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.ItalianPizza,
  },
  {
    name: "Siciliana",
    number: 304,
    ingredients: "Ost, Tomatsaus, Skinke, Paprika, Oliven, Champignon.",
    price: 144,
    allergens: "Hvitløk, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.ItalianPizza,
  },
  {
    name: "Pizza Vesuvio",
    number: 305,
    ingredients: "Ost, Tomatsaus, Skinke.",
    price: 144,
    allergens: "Hvitløk, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.ItalianPizza,
  },
  {
    name: "Capricosa",
    number: 306,
    ingredients: "Ost, Tomatsaus, Skinke, Champignon.",
    price: 144,
    allergens: "Hvitløk, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.ItalianPizza,
  },
  {
    name: "Caramba (sterk)",
    number: 307,
    ingredients:
      "Ost, Tomatsaus, Skinke, Reker, Løk, Champignon. (Sterk saus).",
    price: 144,
    allergens: "Hvitløk, Melk, Tomat, Hvete, Skalldyr",
    img: "pizza1.png",
    foodType: FoodType.ItalianPizza,
  },
  {
    name: "Milano",
    number: 308,
    ingredients: "Ost, Tomatsaus, Løk, Pepperoni.",
    price: 144,
    allergens: "Hvitløk, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.ItalianPizza,
  },
  {
    name: "Romana (Innbakt)",
    number: 309,
    ingredients:
      "Ost, tomatsaus, Biff- el. Kebabkjøtt, Løk, Hvitløk. (Innbakt).",
    price: 144,
    allergens: "Hvitløk, Melk, Tomat, Hvete",
    img: "pizza1.png",
    foodType: FoodType.ItalianPizza,
  },
  {
    name: "Sorrento",
    number: 310,
    ingredients: "Ost, tomatsaus, skinke, reker.",
    price: 144,
    allergens: "Hvitløk, Melk, Tomat, Hvete, Skalldyr",
    img: "pizza1.png",
    foodType: FoodType.ItalianPizza,
  },
  {
    name: "Vegetar Pizza",
    number: 311,
    ingredients: "Ost, Tomatsaus, Paprika, Champignon, Tomater, Løk og Oliven.",
    price: 144,
    allergens: "Hvitløk, Melk, Tomat, Hvete, Skalldyr",
    img: "pizza1.png",
    foodType: FoodType.ItalianPizza,
  },
  {
    name: "Hawaiana",
    number: 312,
    ingredients: "Ost, tomatsaus, ananas, skinke.",
    price: 154,
    allergens: "Hvitløk, Melk, Tomat, Hvete, Skalldyr",
    img: "pizza1.png",
    foodType: FoodType.ItalianPizza,
  },
  {
    name: "U.F.O. (Innbakt)",
    number: 313,
    ingredients:
      "Dobbel bunn, ost, tomatsaus, skinke, champignon, biff. (Innbakt).",
    price: 154,
    allergens: "Hvitløk, Melk, Tomat, Hvete, Skalldyr",
    img: "pizza1.png",
    foodType: FoodType.ItalianPizza,
  },
  {
    name: "Napoli",
    number: 314,
    ingredients: "Ost, tomatsaus, kebabkjøtt, løk og champignon.",
    price: 154,
    allergens: "Hvitløk, Melk, Tomat, Hvete, Skalldyr",
    img: "pizza1.png",
    foodType: FoodType.ItalianPizza,
  },
  {
    name: "Quattro stagione",
    number: 315,
    ingredients: "Ost, tomatsaus, skinke, champignon, reker, muslinger.",
    price: 154,
    allergens: "Hvitløk, Melk, Tomat, Hvete, Skalldyr",
    img: "pizza1.png",
    foodType: FoodType.ItalianPizza,
  },
];

const Tilbehør: FoodMenu[] = [
  {
    name: "Hvit Saus",
    allergens: "Hvitløk",
    foodType: FoodType.Tilbehør,
    img: "pizza1.png",
    ingredients: "",
    number: 0,
    price: 19,
  },
  {
    name: "Tomatsaus",
    allergens: "Tomat",
    foodType: FoodType.Tilbehør,
    img: "pizza1.png",
    ingredients: "",
    number: 0,
    price: 19,
  },
  {
    name: "Kryddermix",
    allergens: "",
    foodType: FoodType.Tilbehør,
    img: "pizza1.png",
    ingredients: "Spice it up!",
    number: 0,
    price: 7,
  },
  {
    name: "Ekstra igredienser på stor pizza",
    allergens: "",
    foodType: FoodType.Tilbehør,
    img: "pizza1.png",
    ingredients: "Pr. ingrediens",
    number: 0,
    price: 15,
  },
];

const pasta: FoodMenu[] = [
  {
    name: "Spaghetti Bolognese",
    number: 203,
    ingredients: "Spaghetti med kjøttsaus.",
    allergens: "Tomat, Hvitløk",
    foodType: FoodType.Pasta,
    img: "bolognese-placeholder.jpg",
    price: 119,
  },
  {
    name: "Rigatoni al Chef",
    number: 205,
    ingredients: "Rigatoni med biff og fløtesaus.",
    allergens: "Tomat, Hvitløk, Melk",
    foodType: FoodType.Pasta,
    img: "pizza1.png",
    price: 129,
  },
  {
    name: "Lasagne al Forno",
    number: 207,
    ingredients:
      "Gratinerte grønne og hvite pastablader med bechamel og kjøttsaus.",
    allergens: "Tomat, Hvitløk, Ost",
    foodType: FoodType.Pasta,
    img: "alforno-placeholder.jpg",
    price: 129,
  },
  {
    name: "Penne al Capone",
    number: 208,
    ingredients:
      "Rigatoni, strimlet biff, paprika, champignon og fløtesaus. (Sterk)",
    allergens: "Tomat, Hvitløk, Ost",
    foodType: FoodType.Pasta,
    img: "pizza1.png",
    price: 139,
  },
  {
    name: "Spaghetti Con Sugo d'Agnello",
    number: 210,
    ingredients:
      "Spaghetti med strimlet biff, hvitvin, hvitløk, fløte, bacon og tomatsaus.",
    allergens: "Tomat, Hvitløk, Melk",
    foodType: FoodType.Pasta,
    img: "pizza1.png",
    price: 139,
  },
];

const BarneMeny: FoodMenu[] = [
  {
    name: "Lasagne al Forno",
    number: 316,
    ingredients:
      "Gratinerte grønne og hvite pastablader med bechamel og kjøttsaus.",
    allergens: "Ost, Tomat, Hvitløk",
    foodType: FoodType.BarneMeny,
    img: "pizza1.png",
    price: 99,
  },
  {
    name: "Spaghetti Bolognese - Liten",
    number: 317,
    ingredients: "Spaghetti med kjøttsaus.",
    allergens: "Ost, Tomat, Hvitløk",
    foodType: FoodType.BarneMeny,
    img: "pizza1.png",
    price: 89,
  },
  {
    name: "1/4 Kylling",
    number: 318,
    ingredients: "Stekt kylling med pommes frites.",
    allergens: "",
    foodType: FoodType.BarneMeny,
    img: "pizza1.png",
    price: 89,
  },
  {
    name: "Barnepizza",
    number: 319,
    ingredients: "Liten pizza for barn med ost, skinke og tomatsaus.",
    allergens: "Ost, Tomat, Hvitløk",
    foodType: FoodType.BarneMeny,
    img: "pizza1.png",
    price: 94,
  },
  {
    name: "Pølse & Pommes frites",
    number: 320,
    ingredients: "Pølse /m pommes frites",
    allergens: "Ost, Tomat, Hvitløk",
    foodType: FoodType.BarneMeny,
    img: "pizza1.png",
    price: 94,
  },
];

const SmåRetter: FoodMenu[] = [
  {
    name: "Cocktail di Gamberetti",
    number: 0,
    ingredients: "Rekecocktail",
    allergens: "Skalldyr, Hvete",
    foodType: FoodType.SmåRetter,
    img: "pizza1.png",
    price: 99,
  },
  {
    name: "Insalata Frutti di Mar",
    number: 0,
    ingredients: "Grønn salat, reker, muslinger, tomat, champignon.",
    allergens: "Skalldyr, Bløtdyr, Tomat",
    foodType: FoodType.SmåRetter,
    img: "pizza1.png",
    price: 104,
  },
  {
    name: "Insalata della Casa",
    number: 0,
    ingredients: "Grønn salat, champignon, avocado, tomater.",
    allergens: "Tomat",
    foodType: FoodType.SmåRetter,
    img: "pizza1.png",
    price: 104,
  },
];

const PommesFrites: FoodMenu = {
  name: "Pommes Frites (chips)",
  allergens: "",
  foodType: FoodType.FastFood,
  img: "frites-placeholder.jpg",
  ingredients: "Legg til pommes frites",
  number: 0,
  price: 54,
};
const TilleggBacon: FoodMenu = {
  name: "Tillegg Bacon",
  allergens: "",
  foodType: FoodType.FastFoodBurger,
  img: "bacon-placeholder.jpg",
  ingredients: "2 skiver bacon på burger",
  number: 0,
  price: 10,
};

const FastFood: FoodMenu[] = [
  {
    name: "Kebab med pitabrød",
    number: 0,
    ingredients: "Kebab av lam i pitabrød.",
    allergens: "Tomat",
    foodType: FoodType.FastFoodKebab,
    img: "pizza1.png",
    price: 94,
  },

  {
    name: "Kebabtallerken",
    number: 0,
    ingredients: "Kebab av lam med pommes frites.",
    allergens: "Tomat",
    foodType: FoodType.FastFoodKebab,
    img: "pizza1.png",
    price: 99,
  },
  {
    name: "Kyllingkebab med pitabrød",
    number: 0,
    ingredients: "Kebab av kylling i pitabrød.",
    allergens: "Tomat",
    foodType: FoodType.FastFoodKebab,
    img: "pizza1.png",
    price: 99,
  },

  {
    name: "Kyllingkebab tallerken",
    number: 0,
    ingredients: "Kebab av kylling med pommes frites.",
    allergens: "Tomat",
    foodType: FoodType.FastFoodKebab,
    img: "pizza1.png",
    price: 99,
  },
  {
    name: "Kebabrulle",
    number: 0,
    ingredients: "Kebab av lam.",
    allergens: "Tomat",
    foodType: FoodType.FastFoodKebab,
    img: "pizza1.png",
    price: 99,
  },

  {
    name: "Hamburger",
    number: 0,
    ingredients: "Hamburger i brød med salat, tomat, sylteagurk og dressing.",
    allergens: "Tomat",
    foodType: FoodType.FastFoodBurger,
    img: "hamburger-placeholder.jpg",
    price: 89,
    preText: "130g",
    priceArray: [
      {
        desc: "130g",
        price: 89,
      },
      {
        desc: "160g",
        price: 94,
      },
      {
        desc: "190g",
        price: 99,
      },
      {
        desc: "250g",
        price: 109,
      },
    ],
  },
  {
    name: "Cheeseburger",
    number: 0,
    ingredients:
      "Hamburger i brød med ost, salat, tomat, sylteagurk og dressing.",
    allergens: "Tomat",
    foodType: FoodType.FastFoodBurger,
    img: "cheeseburger-placeholder.jpg",
    price: 84,
    preText: "70g",
    priceArray: [
      {
        desc: "70g",
        price: 84,
      },
      {
        desc: "130g",
        price: 94,
      },
      {
        desc: "190g",
        price: 104,
      },
    ],
  },
  {
    name: "Kyllingburger",
    number: 0,
    ingredients: "Kyllingburger i brød med salat, tomat og dressing.",
    allergens: "Tomat",
    foodType: FoodType.FastFoodBurger,
    img: "kyllingburger-placeholderjpg.jpg",
    price: 89,
  },
  {
    name: "Fiskeburger",
    number: 0,
    ingredients: "Fiskeburger i brød med salat, tomat og dressing.",
    allergens: "Tomat",
    foodType: FoodType.FastFoodBurger,
    img: "fiskkeburger-placeholder.jpg",
    price: 84,
  },
  PommesFrites,
  TilleggBacon,
];

const MeatAndFish: FoodMenu[] = [
  {
    name: "Karbonader",
    number: 0,
    ingredients: "Med stek løk og salat",
    allergens: "",
    foodType: FoodType.MeatAndFish,
    img: "pizza1.png",
    price: 119,
  },
  {
    name: "Kylling med tilbehør",
    number: 0,
    ingredients: "",
    allergens: "",
    foodType: FoodType.MeatAndFish,
    img: "pizza1.png",
    price: 119,
  },
  {
    name: "Biffsnadder",
    number: 0,
    ingredients: "Med pommes frites og bearnaisesaus",
    allergens: "",
    foodType: FoodType.MeatAndFish,
    img: "pizza1.png",
    price: 144,
  },
  {
    name: "Panert fisk",
    number: 0,
    ingredients: "Med remulade og salat",
    allergens: "",
    foodType: FoodType.MeatAndFish,
    img: "pizza1.png",
    price: 144,
  },
];

export const foodMenu: FoodMenu[] = [
  ...AmericanPizzas,
  ...ItalianPizzas,
  ...Tilbehør,
  ...pasta,
  ...BarneMeny,
  ...SmåRetter,
  ...FastFood,
  ...MeatAndFish,
];
